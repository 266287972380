import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import logo from './4098.svg';
import './App.css';
import Terms from './Terms';
import Privacy from './Privacy';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="App-main">
          <Routes>
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/" element={
              <div className="App-store-button">
                <a href="https://apps.apple.com/au/app/fampack-for-the-ones/id6476157901">
                  <img
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                    alt="Download on the App Store"
                  />
                </a>
              </div>
            } />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

function Header() {
  const location = useLocation();
  const hideHeaderPaths = ['/terms', '/privacy'];

  if (hideHeaderPaths.includes(location.pathname)) {
    return null;
  }

  return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <nav className="App-nav">
        <Link to="/terms">Terms</Link>
        <span className="separator">&</span>
        <Link to="/privacy">Privacy</Link>
      </nav>
    </header>
  );
}

export default App;

